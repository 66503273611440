/**
 * (c) webXells GmbH
 *
 * This computer program is the sole property of webXells GmbH
 * (http://www.webXells.com) and is protected under the German Copyright Act
 * (paragraph 69a UrhG). All rights are reserved. Making copies,
 * duplicating, modifying, using or distributing this computer program
 * in any form, without prior written consent of webXells, is
 * prohibited. Violation of copyright is punishable under the
 * German Copyright Act (paragraph 106 UrhG). Removing this copyright
 * statement is also a violation.
 *
 * @author: Christian Lahrz-Beckmann
 * @since: 26.06.2019 - 17:29
 * @version: 0.0.1
 * @project: Messespecial-Generator
 * @file: polyfills.js
 * @description:
 */

import cssVars from 'css-vars-ponyfill';

// https://blog.julitec.com/dev/pr%C3%BCfen-ob-internet-explorer-verwendet-wird-inklusive-ie-11
const getInternetExplorerVersion = () => {
    let rV = -1; // Return value assumes failure.

    if (navigator.appName == 'Microsoft Internet Explorer' || navigator.appName == 'Netscape') {
        let uA = navigator.userAgent;
        let rE = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");

        if (rE.exec(uA) != null) {
            rV = parseFloat(RegExp.$1);
        }
        /*check for IE 11*/
        else if (!!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            rV = 11;
        }
    }

    return rV;
};

let ieVersion = getInternetExplorerVersion();
if (ieVersion > -1) {
    cssVars({
        variables: {}
    }); // fuer IE 10
}