import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import $ from "jquery";

let _selector_gallery = '.pswp',
    _selector_title = '.article-headline-pswp',
    _selector_caption = 'figcaption',
    _selector_link = '.pswp-link',
    _cache = {},
    _gallery = false,
    _just_opened = false,
    _initialized = false;

export default new class PhotoSwipeController {
    constructor() {

    }

    /**
     * Galerie Items initialisieren
     *
     * @param container
     * @param item
     * @param articleId
     */
    init(container, item, articleId = 0) {

        if (!$(container).length) return;

        let galleryId = Object.keys(_cache).length;

        let galleryObj = {
            id: 'gallery-' + (Object.keys(_cache).length + 1),
            container: $(container),
            items: []
        };

        // collect gallery items
        $(item, galleryObj.container).each(function (index) {
            // collect info from item
            let $link = $(_selector_link, this),
                data = {
                    w: $link.attr('data-width'),
                    h: $link.attr('data-height'),
                    src: $link.attr('href'),
                    title: $(_selector_caption, this).html(),
                    img: $('img', $link).get(0),
                    elem: $('img', $link)
                };

            // store index on link
            $link.attr('data-index', index);

            // store gallery id on link
            $link.attr('data-gallery', galleryId);

            // add item data to gallery list
            galleryObj.items.push(data);
        });

        // store gallery info in _cache
        if (!_cache[articleId]) {
            _cache[articleId] = [];
        }
        _cache[articleId].push(galleryObj);

        _initialized = true;
    };

    /**
     * Controller Variablen zurücksetzen
     *
     * @param articleId
     */
    destroy(articleId = 0) {

        if (_initialized) {
            _gallery = false;
            _cache[articleId] = [];
            delete _cache[articleId];
            $(_selector_gallery).first().off('click.pswp');
        }

        _initialized = false;
    }

    /**
     * Galerie öffnen
     *
     * @param gallery
     * @param image
     * @param articleId
     */
    show(gallery, image, articleId = 0) {

        let galleryId = parseInt(gallery, 10) - 1;
        let index = parseInt(image, 10) - 1;

        let pswp = $(_selector_gallery).first();

        //Vgl. mit http://photoswipe.com/documentation/options.html
        let options = {
            history: false,

            closeEl: true,
            captionEl: true,
            fullscreenEl: true,
            zoomEl: false, //Zoom deaktiviert (stattdessen 1/3, 2/3 Klickstrecken)
            shareEl: false,
            counterEl: true,
            arrowEl: true,
            preloaderEl: true,

            barsSize: {top: 44, bottom: 130},

            maxSpreadZoom: 1,
            bgOpacity: 0.95,
            galleryUID: galleryId,
            closeElClasses: ['caption', 'ui'], //Schliessen nur bestimmten Flächen gestatten (https://github.com/dimsemenov/PhotoSwipe/issues/762)

            getThumbBoundsFn: function (index) {
                if (typeof _cache[articleId] !== 'undefined') {
                    let thumbnail = _cache[articleId][galleryId].items[index].elem.get(0);

                    let pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    let rect = thumbnail.getBoundingClientRect();
                    let offset = -1 * parseInt($(_selector_gallery).css('top'), 10);

                    return {x: rect.left, y: rect.top + pageYScroll + offset, w: rect.width};
                }
            },

            getNumItemsFn: function () {
                return _cache[articleId][galleryId].items.length;
            },

            index: index
        };

        _just_opened = true;

        // initialize photoswipe gallery
        _gallery = new PhotoSwipe(pswp.get(0), PhotoSwipeUI_Default, $.extend({}, _cache[articleId][galleryId].items), options);

        (function () {
            //Artikeltitel übernehmen
            let title = $('[data-article-module="headline"]').text();
            let subTitle = $('[data-article-module="subheadline"]').text();
            let $titleContainer = $(_selector_title);
            $titleContainer.find('.title').text(title);
            $titleContainer.find('.subtitle').text(subTitle);
        })();

        /*
         * Click auf linke oder rechte Hälfte NEBEN dem Bild ist wie ein Klick auf den next- prev Button
         */
        $(pswp.get(0)).off('click.pswp');
        $(pswp.get(0)).on('click.pswp', function (e) {
            //nicht 2mal weiternavigieren beim Button
            if ($(e.target).is('button')) {
                return;
            }

            let width = $('body').width();

            //40% links vorheriges Bild zeigen - rechts nächstes Bild (vgl. mit baunetz.de)
            if (e.pageX < (width * 0.4)) {
                _gallery.prev();
            } else {
                _gallery.next();
            }
        });

        _gallery.listen('close', function () {
            $(window).trigger('pswp-close');
        });

        _gallery.listen('afterChange', function () {
            if (!_just_opened) {
                $(window).trigger('pswp-change', _gallery.getCurrentIndex());
            } else {
                _just_opened = false;
            }

        });

        _gallery.init();
    }

    /**
     * Schliessen der Galerie
     */
    close() {

        if (_gallery) {
            _gallery.close();
            _gallery = false;
        }
    }

    /**
     * Ist die Galerie aktiv?
     *
     * @returns {boolean}
     */
    isOpen() {
        return !!_gallery;
    }

};