/**
 * (c) webXells GmbH
 *
 * This computer program is the sole property of webXells GmbH
 * (http://www.webXells.com) and is protected under the German Copyright Act
 * (paragraph 69a UrhG). All rights are reserved. Making copies,
 * duplicating, modifying, using or distributing this computer program
 * in any form, without prior written consent of webXells, is
 * prohibited. Violation of copyright is punishable under the
 * German Copyright Act (paragraph 106 UrhG). Removing this copyright
 * statement is also a violation.
 *
 * @author: Christian Lahrz-Beckmann
 * @since: 26.06.2019 - 17:30
 * @version: 0.0.1
 * @project: Messespecial-Generator
 * @file: app.js
 * @description:
 */

import "./ie.polyfill";
import $ from 'jquery';
import "slick-carousel/slick/slick";
import PhotoSwipeController from "./gallery";
import VideoSwipeController from "./video-gallery";
// import {iframeResizer, iframeResizerContentWindow} from "iframe-resizer";

window.$ = $;

class Controller {
    constructor() {
        this.$menu = $('.bs-nav');
        this.$gallery = $('.bs-galerie');
        this.$thumbs = $('.bs-galerie__thumbs');
        this.$thumbsOpener = $('.bs-galerie__thumbs-opener');

        this.init();
    }

    init() {
        this.partnerSlider();

        if (this.$gallery.length) {
            this.gallerySlider();
            this.galleryPhotoswipe();
            this.galleryThumbsSlider();
        }

        this.initEventlistener();
        this.resizeIFrame();
    }

    initEventlistener() {
        this.$menu.find('.bs-nav__burger-menu').on('click', (e) => {
            e.preventDefault();
            this.toggleMenu();
        });

        this.$menu.find('.bs-nav__menu-close').on('click', (e) => {
            e.preventDefault();
            this.toggleMenu();
        });

        /*
         * Video starten bei data-video Attributen, die href wird dem iframe beigefügt
         */
        $(document).on('click', '[data-video]', (e) => {
            e.preventDefault();
            VideoSwipeController.showVideo(e.currentTarget);
        });
    }

    toggleMenu() {
        this.$menu.toggleClass('bs-nav--open');
    }

    /**
     * Partner Footer Slide Fade durchläuft alle Partner
     */
    partnerSlider() {
        if (!$.fn.slick) return;

        $('.bs-partner__link-list').slick({
            autoplay: true,
            autoplaySpeed: 3 * 1000, // 3s
            arrows: false,
            appendDots: false,
            centerMode: false,
            draggable: false,
            fade: true,
            infinite: true,
            slidesPerRow: 3
        });
    }

    gallerySlider() {
        if (!this.$gallery.length) return;

        /**
         * Bildergalerie starten per Klick auf Einzelbild, öffnet die passende Bildnummer
         * nur die 1. Bildgalerie verwenden, es gibt nur eine, alle anderen Bilder sind
         * Einzelbilder der einen Galerie
         */
        $(document).on('click', '.pswp-link', (e) => {
            e.preventDefault();
            const $container = $('.bs-galerie').first();
            if (!$container.length) return;

            // Per URL des Links den Index in der Hauptgalerie herausfinden
            let src = $(e.currentTarget).attr('href');
            const $link = $container.find('.slick-slide:not(.slick-cloned) .pswp-link[href="' + src + '"]');
            if (!$link.length) return;

            let imageNumber = $($container.find('.slick-slide:not(.slick-cloned) .pswp-link')).index($link) + 1;

            // 1. Bildergalerie aufrufen
            PhotoSwipeController.show(1, imageNumber);
        });

        // Bildnummerierung, sofern vorhanden
        const $galleryNumbers = $('.bs-galerie__numbers');
        if ($galleryNumbers.length) {

            /**
             * https://stackoverflow.com/questions/2998784/how-to-output-integers-with-leading-zeros-in-javascript
             *
             * @param num
             * @param size
             * @returns {string}
             */
            const padNumber = function pad(num, size) {
                return ('000000000' + num).substr(-size);
            };

            this.$gallery.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                let i = (currentSlide ? currentSlide : 0) + 1;
                i = padNumber(i, 2);
                let count = slick.slideCount;
                count = padNumber(count, 2);
                $galleryNumbers.text(i + ' / ' + count);
            });

        }

        this.$gallery.slick({
            autoplay: false,
            arrows: true,
            appendDots: false,
            draggable: true,
            fade: false,
            slidesPerRow: 1,
            asNavFor: '.bs-galerie__thumbs', // Navigation syncing
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Vorheriges Foto" type="button">Vorheriges Foto</button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Nächstes Foto" type="button">Nächstes Foto</button>'
        });

    }

    galleryPhotoswipe() {
        PhotoSwipeController.init('.bs-galerie', '.slick-slide:not(.slick-cloned) .pswp-item');
    }

    /**
     * Weitere Fotos Slider der Hauptbildergalerie
     * https://kenwheeler.github.io/slick/
     */
    galleryThumbsSlider() {
        if (!this.$thumbs.length) return;

        /**
         * Weitere Fotos anklicken zeigt Thumbnails Navigation der Hauptgalerie an
         */
        this.$thumbsOpener.on('click', (e) => {
            e.preventDefault();
            this.showThumbsSlider();
        });

        // Click außerhalb der Galerie versteckt die Thumbnails wieder
        $(document).on('click', (e) => {
            // weder in der Galerie noch in Photoswipe ausblenden - nur außerhalb davon
            if ($(e.target).closest('.bs-galerie__container').length ||
                $(e.target).closest('.pswp').length) return;

            this.hideThumbsSlider();
        });

        this.$thumbs.slick({
            autoplay: false,
            arrows: true,
            appendDots: false,
            draggable: false,
            centerMode: false,
            fade: false,
            slidesToShow: 5,
            asNavFor: '.bs-galerie', // Navigation syncing
            focusOnSelect: true,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Vorheriges Foto" type="button">Vorheriges Foto</button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Nächstes Foto" type="button">Nächstes Foto</button>'
        });
    }

    showThumbsSlider() {
        this.$thumbs.removeClass('bs-galerie__thumbs--is-hidden');
        this.$thumbsOpener.hide();
    }

    hideThumbsSlider() {
        if (this.$thumbs.hasClass('bs-galerie__thumbs--is-open')) return;

        this.$thumbs.addClass('bs-galerie__thumbs--is-hidden');
        this.$thumbsOpener.show();
    }

    resizeIFrame() {
        if (typeof iFrameResize === 'undefined') return;

        const $iframes = $('iframe[data-resize]');
        if (!$iframes.length) return;

        try {

            iFrameResize({}, '[data-resize]');

            $iframes.each(function () {
                $(this).on('load', function () {
                    iFrameResize({}, this);
                });
            });

            $(window).on('resize', function () {
                $iframes.each(function () {
                    iFrameResize({}, this);
                });
            });

        } catch (e) {

        }
    }
}

const Ctrl = new Controller();

window.Controller = Ctrl;