import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import $ from "jquery";

export default new class VideoSwipeController {
    constructor() {
        this.$container = $('.pswp');
    }

    showVideo($video) {
        $video = $($video);

        if (!$video.length) return;

        const items = [];

        $video.each((index, video) => {
            let url = $(video).attr('href');
            if (typeof url === 'string' && url !== '') {
                url += (url.indexOf('?') > -1) ? '&' : '?';

                // Vimeo Video URL-Parameter
                if (url.indexOf('vimeo.com') > -1) {
                    url += 'autoplay=1&hd=1&show_title=1&show_byline=1&show_portrait=0&fullscreen=1"';
                }

                let item = {
                    html: `
                        <div class="bs-video">
                            <iframe class="pswp__video" src="${url}" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>`
                };

                items.push(item);
            }
        });

        const options = {
            history: false,

            closeEl: true,
            captionEl: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
            counterEl: false,
            arrowEl: true,
            preloaderEl: false,

            barsSize: {top: 44, bottom: 130},

            maxSpreadZoom: 1,
            bgOpacity: 0.95,
            closeElClasses: ['caption', 'ui'], //Schliessen nur bestimmten Flächen gestatten (https://github.com/dimsemenov/PhotoSwipe/issues/762)
        };

        const gallery = new PhotoSwipe(this.$container.get(0), PhotoSwipeUI_Default, items, options);

        gallery.listen('close', () => {
            // Video stoppen
            this.$container.find('.bs-video').remove();
            this.$container.removeClass('pswp--video');
        });

        this.$container.addClass('pswp--video');
        gallery.init();
    }
}